import React from 'react';

import Layout from '../../components/Layout';

const NotFoundPage = () => (
  <Layout language="en" slug="/en/404">
    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
      <div>
        <h1>Page not found</h1>
        <p>We could not find the requested page. Please visit our home page.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;